import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['mask', 'value']

  connect() {
    this.mask(this.valueTarget.value)
  }

  createMask(value) {
    const toDecimal = (Number(value) / 100).toFixed(2)

    if (toDecimal > 0) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        toDecimal,
      )
    } else {
      return null
    }
  }

  removeMask(value) {
    return value.replace(/\D/g, '').substring(0, 9)
  }

  mask(value) {
    this.valueTarget.value = this.removeMask(value)
    this.maskTarget.value = this.createMask(this.valueTarget.value)
  }

  update(event) {
    this.mask(event.target.value.replace(/\D/g, ''))
  }
}
