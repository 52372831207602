import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.add('animate__fadeOut')
      this.element.onanimationend = () => this.element.remove()
    }, 3000)
  }
}
