import { Application } from '@hotwired/stimulus'
import { Dropdown } from 'tailwindcss-stimulus-components'
import { Attachments } from '@mguidetti/stimulus-activestorage-attachment'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }

application.register('dropdown', Dropdown)
application.register('attachments', Attachments)
