import { Controller } from '@hotwired/stimulus'
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    data: Array,
  }

  initialize() {
    this.initChart()
  }

  connect() {
    this.chart.render()
  }

  disconnect() {
    this.chart.destroy()
  }

  initChart() {
    const options = {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        height: '100%',
        width: '100%',
      },
      stroke: {
        width: 4,
        lineCap: 'butt',
      },
      markers: {
        size: 5,
        hover: {
          size: 8,
        },
      },
      series: this.dataValue,
      grid: {
        padding: {
          top: -20,
          left: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: value => this.formatUSD(value),
          offsetX: -17,
        },
        tooltip: {
          enabled: false,
        },
      },
      xaxis: {
        type: 'numeric',
        tickAmount: 'dataPoints',
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      noData: {
        text: 'No data yet',
      },
      colors: ['#556edd', '#ED8936'],
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
          const color = w.globals.colors[seriesIndex]

          const eventsHTML = data.events
            .map(
              event => `
                <div class="px-4 py-2">
                  <div>
                    <span
                    class="apexcharts-legend-marker"
                    style="background: ${color} none repeat scroll 0% 0% !important;
                    height: 12px; width: 12px; border-radius: 12px;"></span>
                    <span style="color: ${color}">${event.message}</span>
                  </div>
                  <div class="text-right">
                    <span class="text-xs text-gray-500 mt-1">${event.date}</span>
                  </div>
                </div>
              `,
            )
            .join('')

          return `
            <div>
              <div class="p-2 border-b bg-gray-100" style="color: ${color};">
                ${data.header}
              </div>
              <div class="divide-y">
                ${eventsHTML}
              </div>
            </div>
          `
        },
      },
    }

    this.chart = new ApexCharts(this.element, options)
  }

  formatUSD(value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(value)
  }
}
